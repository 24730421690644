import { useState } from 'react';
import { styled } from "ui/stitches.config";
import { ReactComponent as DefaultAccountImage } from '../../ui/cricket/match/assets/icon-default-account-img.svg';
import { Config } from '../config';

const ImageViewWrapper = styled('div', {
  maxWidth: '22px',
  maxHeight: '22px',
  overflow: 'hidden',
  transition: 'all 0.5s ease-out',
  position: 'relative',

  color: '$colorGrey',
  textAlign: 'center',
  fontWeight: 500,
  fontSize: 'calc(22px * 0.55)',
  lineHeight: '22px',

  '.image': {
    opacity: 0,
  },

  '.image, .default-image': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  '.default-image': {
    width: '22px',
    height: '22px',
  },

  'span': {
    lineHeight: '22px',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: '#80A9D7',
    color: '$colorDarkBlue1',
  },

  '&.rounded': {
    'span, img': {
      borderRadius: '50%',
    }
  },

  '&.image-loaded': {
    'span': {
      opacity: '0',
    },
    '.image': {
      opacity: '1',
    },
  },

  '&.use-render': {
    backgroundColor: 'transparent',
    'span': {
      opacity: '0',
      height: '0',
      overflow: 'hidden',
    },
    '.image': {
      display: 'none',
    }
  }

});

export function ImageView({
  url,
  showInitial,
  defaultImageUrl,
  renderDefaultView,
  name,
  rounded,
  withCircle,
  withStatus,
  size,
  className,
}: any) {
  let resolvedUrl = url;
  if (resolvedUrl && !resolvedUrl.startsWith('https:') && !resolvedUrl.startsWith('http:')) {
    resolvedUrl = `${Config.staticUrl}${url}`;
  }

  const [imageLoad, setImageLoad] = useState(false);
  const classes = ['rz-image-view'];

  if (rounded) classes.push('rounded');
  if (withCircle) classes.push('with-circle');
  if (size) classes.push(`size-${size}`);
  if (imageLoad) classes.push('image-loaded');
  if (!imageLoad && renderDefaultView) classes.push('use-render');
  if (className) classes.push(className);

  return (
    <ImageViewWrapper className={classes.join(' ')}>
      {showInitial && (
        <span>{(name ?? 'x')[0].toUpperCase()}</span>
      )}
      <img
        className="image"
        src={resolvedUrl}
        alt={name!}
        onError={() => setImageLoad(false)}
        onLoad={() => setImageLoad(true)}
      />
      {!imageLoad && defaultImageUrl && (
        <img
          className="default-image"
          src={defaultImageUrl}
          alt={name!}
        />
      )}
      {!imageLoad && renderDefaultView && renderDefaultView()}
    </ImageViewWrapper>
  );
}


const AccountImageViewWrapper = styled('div', {
  '.rz-image-view': {
    'span, img': {
      borderRadius: '4px',
    },
    'span': {
      backgroundColor: '#80A9D7',
      color: '$colorGrey',
    }
  }
});

export function AccountImageView({ account, ...imageProps }: any) {
  return (
    <AccountImageViewWrapper>
      <ImageView
        url={account?.profile?.picture_url}
        renderDefaultView={() => (<DefaultAccountImage className="default-image" />)}
        name={account?.profile?.name}
        showInitial
        rounded
        {...imageProps}
      />
    </AccountImageViewWrapper>
  );
}

const UserImageViewWrapper = styled('div', {
  '.rz-image-view': {
    'span': {
      backgroundColor: '#80A9D7',
    }
  }
});

export function UserImageView({
  user,
  ...imageProps
}: any) {
  return (
    <UserImageViewWrapper>
      <ImageView
        url={user?.profile?.picture_url}
        name={user?.profile?.first_name}
        showInitial
        rounded
        size="size-24"
        {...imageProps}
      />
    </UserImageViewWrapper>
  );
}