import { GQLError } from "core/status";
import { ReactNode } from "react";
import { styled } from "ui/stitches.config";


type GqlErrorViewProps = {
  error?: GQLError;
  render?: (error: GQLError) => ReactNode;
}


const GqlErrorViewStyle = styled('div', {
});
 

export function GqlErrorView({ error, render }: GqlErrorViewProps) {
  return (
    <GqlErrorViewStyle>
      {error && error?.hasError() && (
        <div>
          {render && render(error)}
          {!render && (
            <ul>
              {error.getRzErrors().map((rzError, i) => (
                <li key={i}>
                  {rzError.message}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </GqlErrorViewStyle>
  )
}