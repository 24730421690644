import { useEffect, useState } from "react";
import { GQLError, ResponseStatus } from "core/status";
import { styled } from "ui/stitches.config";
import { ApolloError } from "@apollo/client";
import { Loader } from "./Loader";
import { GqlErrorView } from "./GqlErrorView";


type GqlStatusProps = {
  loading?: boolean;
  error?: ApolloError;
  status?: ResponseStatus;
}


const GqlStatusStyle = styled('div', {
});
 

export function GqlStatus({ loading, error, status }: GqlStatusProps) {
  const [gerror, setGError] = useState<GQLError>(new GQLError());

  useEffect(() => {
    if (error) {
      gerror.apollo = error;
      setGError(gerror);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (status) {
      gerror.status = status;
      setGError(gerror);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <GqlStatusStyle>
      <Loader loading={loading} />
      <GqlErrorView error={gerror} />
    </GqlStatusStyle>
  )
}