import { ReactNode } from "react";
import { styled } from "ui/stitches.config";


type LoaderProps = {
  message?: string;
  loading?: boolean;
  children?: ReactNode;
}


const LoaderStyle = styled('div', {
});
 

export function Loader({ message, loading, children }: LoaderProps) {
  return (
    <LoaderStyle>
      {loading && (
        <div>
          {children}
          {(!children) && (message || 'Loading...')}
        </div>
      )}
    </LoaderStyle>
  )
}