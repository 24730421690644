// import * as Updates from 'expo-updates';
// const isProd = Updates.channel.startsWith('prod');
import process from "process";
// import path from "path";
// const dotenv = require('dotenv');

// dotenv.config({ path: path.resolve(process.cwd(), '.env.local') });
// dotenv.config({ override: true });

const isProd = process.env.NODE_ENV === "production";

export const WebConfig = {
  gqlSrc: process.env.REACT_APP_RZ_GQL_SRC || "https://api.sports.roanuz.com/v5/gql/",
  apiKey: process.env.REACT_APP_RZ_API_KEY,
  isProd,
} as const;
