import { styled } from "../ui/stitches.config";

export const HighlightText1 = styled('h1', {
  fontSize: '90px',
  lineHeight: '100px',
  fontWeight : 500,
  '@bp2': {
    fontSize: '120px',
    lineHeight: '130px',
  },
  '@bp3': {
    fontSize: '160px',
    lineHeight: '160px',
  },
});

export const HighlightText2 = styled('h1', {
  fontSize: '70px',
  lineHeight: '80px',
  fontWeight : 700,
  '@bp2': {
    fontSize: '100px',
    lineHeight: '110px',
  },
  '@bp3': {
    fontSize: '120px',
    lineHeight: '144px',
  },
});

export const HighlightText3 = styled('h1', {
  fontSize: '40px',
  lineHeight: '55px',
  fontWeight : 500,
  '@bp2': {
    fontSize: '60px',
    lineHeight: '70px',
  },
  '@bp3': {
    fontSize: '80px',
    lineHeight: '96px',
  },
});

export const HighlightText4 = styled('h1', {
  fontSize: '30px',
  lineHeight: '40px',
  fontWeight : 500,
  '@bp2': {
    fontSize: '40px',
    lineHeight: '50px',
  },
  '@bp3': {
    fontSize: '61px',
    lineHeight: '73px',
  },
});

export const HighlightText5 = styled('h1', {
  fontSize: '70px',
  lineHeight: '80px',
  fontWeight : 500,
  '@bp2': {
    fontSize: '100px',
    lineHeight: '110px',
  },
  '@bp3': {
    fontSize: '120px',
    lineHeight: '144px',
  },
});


export const Font48Bold = styled('h2', {
  fontSize: '24px',
  lineHeight : '28px',
  fontWeight: 700,
  '@bp2': {
    fontSize: '32px',
    lineHeight: '40px',
  },
  '@bp3': {
    fontSize: '48px',
    lineHeight : '48px',
  },

});


export const Font41Bold = styled('h2', {
  fontSize: '24px',
  lineHeight : '28px',
  fontWeight : 700,
  '@bp2': {
    fontSize: '32px',
    lineHeight: '40px',
  },
  '@bp3': {
    fontSize: '41px',
    lineHeight: '50px',
  },
});

export const Font41Medium = styled('h2', {
  fontSize: '24px',
  lineHeight : '28px',
  fontWeight : 500,
  '@bp2': {
    fontSize: '32px',
    lineHeight: '40px',
  },
  '@bp3': {
    fontSize: '41px',
    lineHeight: '50px',
  },
});

export const Font41Regular = styled('h2', {
  fontSize: '24px',
  lineHeight : '28px',
  fontWeight : 400,
  '@bp2': {
    fontSize: '32px',
    lineHeight: '40px',
  },
  '@bp3': {
    fontSize: '41px',
    lineHeight: '50px',
  },
});

export const Font36Bold = styled('h2', {
  fontSize: '22px',
  lineHeight: '24px',
  fontWeight : 700,
  '@bp2': {
    fontSize: '26px',
    lineHeight: '32px',
  },
  '@bp3': {
    fontSize: '36px',
    lineHeight: '43px',
  },
});

export const Font36Medium = styled('h2', {
  fontSize: '22px',
  lineHeight: '24px',
  fontWeight : 500,
  '@bp2': {
    fontSize: '26px',
    lineHeight: '32px',
  },
  '@bp3': {
    fontSize: '36px',
    lineHeight: '43px',
  },
});

export const Font36Regular = styled('h2', {
  fontSize: '22px',
  lineHeight: '24px',
  fontWeight : 400,
  '@bp2': {
    fontSize: '26px',
    lineHeight: '32px',
  },
  '@bp3': {
    fontSize: '36px',
    lineHeight: '43px',
  },
});



export const Font32Bold = styled('h2', {
  fontSize: '20px',
  lineHeight: '22px',
  fontWeight : 700,
  '@bp2': {
    fontSize: '24px',
    lineHeight: '28px',
  },
  '@bp3': {
    fontSize: '32px',
    lineHeight: '38px',
  },
});

export const Font32Medium = styled('h2', {
  fontSize: '20px',
  lineHeight: '22px',
  fontWeight : 500,
  '@bp2': {
    fontSize: '24px',
    lineHeight: '28px',
  },
  '@bp3': {
    fontSize: '32px',
    lineHeight: '38px',
  },
});

export const Font32Regular = styled('h2', {
  fontSize: '20px',
  lineHeight: '22px',
  fontWeight : 400,
  '@bp2': {
    fontSize: '24px',
    lineHeight: '28px',
  },
  '@bp3': {
    fontSize: '32px',
    lineHeight: '38px',
  },
});



export const Font29Bold = styled('h2', {
  fontSize: '20px',
  lineHeight: '22px',
  fontWeight : 700,
  '@bp2': {
    fontSize: '22px',
    lineHeight: '26px',
  },
  '@bp3': {
    fontSize: '29px',
    lineHeight: '35px',
  },
});

export const Font29Medium = styled('h2', {
  fontSize: '20px',
  lineHeight: '22px',
  fontWeight : 500,
  '@bp2': {
    fontSize: '22px',
    lineHeight: '26px',
  },
  '@bp3': {
    fontSize: '29px',
    lineHeight: '35px',
  },
});

export const Font29Regular = styled('h2', {
  fontSize: '20px',
  lineHeight: '22px',
  fontWeight : 400,
  '@bp2': {
    fontSize: '22px',
    lineHeight: '26px',
  },
  '@bp3': {
    fontSize: '29px',
    lineHeight: '35px',
  },
});



export const Font26Bold = styled('h2', {
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight : 700,
  '@bp2': {
    fontSize: '22px',
    lineHeight: '26px',
  },
  '@bp3': {
    fontSize: '26px',
    lineHeight: '32px',
  },
});

export const Font26Medium = styled('h2', {
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight : 500,
  '@bp2': {
    fontSize: '22px',
    lineHeight: '26px',
  },
  '@bp3': {
    fontSize: '26px',
    lineHeight: '32px',
  },
});

export const Font26Regular = styled('h2', {
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight : 400,
  '@bp2': {
    fontSize: '22px',
    lineHeight: '26px',
  },
  '@bp3': {
    fontSize: '26px',
    lineHeight: '32px',
  },
});



export const Font23Bold = styled('h2', {
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight : 700,
  '@bp3': {
    fontSize: '23px',
    lineHeight: '28px',
  },
});

export const Font23Medium = styled('h2', {
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight : 500,
  '@bp3': {
    fontSize: '23px',
    lineHeight: '28px',
  },
});

export const Font23Regular = styled('h2', {
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight : 400,
  '@bp3': {
    fontSize: '23px',
    lineHeight: '28px',
  },
});



export const Font20Bold = styled('h3', {
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight : 700,
  '@bp3': {
    fontSize: '20px',
    lineHeight: '24px',
  },
});

export const Font20Medium = styled('h3', {
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight : 500,
  '@bp3': {
    fontSize: '20px',
    lineHeight: '24px',
  },
});

export const Font20Regular = styled('h3', {
  fontSize: '18px',
  lineHeight: '28px',
  fontWeight : 400,
  '@bp3': {
    fontSize: '20px',
    lineHeight: '24px',
  },
});



export const Font18Bold = styled('h4', {
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight : 700,
});

export const Font18Medium = styled('h4', {
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight : 500,
});

export const Font18Regular = styled('h4', {
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight : 400,
});



export const Font16Bold = styled('p', {
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight : 700,
});

export const Font16Medium = styled('p', {
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight : 500,
});

export const Font16Regular = styled('p', {
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight : 400,

  '&.silent': {
    marginBottom: '10px',
    color: '$colorBlue2',
  }
});


export const Font14Bold = styled('p', {
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight : 700,
});

export const Font14Medium = styled('p', {
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight : 500,
});

export const Font14Regular = styled('p', {
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight : 400,
});



export const Font12Bold = styled('span', {
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight : 700,
});

export const Font12Medium = styled('span', {
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight : 500,
});

export const Font12Regular = styled('span', {
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight : 400,
});









