import { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { AccountsQuery } from "core/user";
import { GqlStatus } from "ui/components/GqlStatus";
import { styled } from "ui/stitches.config";
import { UserContext } from "./UserContext";
import { Font23Bold, Font16Medium } from "typography/typography";
import { ReactComponent as ArrowIcon } from '../ui/cricket/match/assets/icon-arrow-right-filled.svg';
import DefaultImage from '../ui/cricket/match/assets/icon-default-account-img.svg';
import { AccountAccountProfileView } from "core/gql/graphql";
import { setAccount, setAccountkey } from "./localStore";
import { useNavigate } from "react-router-dom";
import { AccountImageView } from "core/helper/Image";
import { ReactComponent as NextIcon } from 'ui/cricket/match/assets/icon-pagination-next.svg';
import { ReactComponent as PrevIcon } from 'ui/cricket/match/assets/icon-pagination-prev.svg';


const AccountPickerStyle = styled('div', {
  padding: '30px 0',
  maxWidth: '320px',
  margin: '0 auto',

  'h2': {
    padding: '0 0 30px',
    textAlign: 'center',
  },
  '.pagination' : {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    padding: '30px 0',
    justifyContent: 'end',

    'button' : {
      cursor: 'pointer',
      background: 'transparent',
      border: '1px solid $colorWhite',
      borderRadius: '8px',
      color: '$text1',
      padding: '0 2px',
    }
  },
  '.link-btn': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 20px',
    transition: 'background 0.3s linear',
    color: '$text1',
    background: '$colorSecondary',
    outline: 'none',
    borderRadius: '8px',
    marginBottom: '8px',
    cursor: 'pointer',
    border: '1px solid $colorSecondary',
    width: '100%',

    '>div': {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',

      'p': {
        textAlign: 'left',
      }
    },

    '&:hover': {
      background: 'transparent',
    },
  }
});

export function AccountPicker() {

  const [pageKey, setPageKey] = useState<string | null>("1")

  const { data, loading, error, refetch } = useQuery(AccountsQuery, {
    variables: {
      pageKey: pageKey
    }
  });
  const userContext = useContext(UserContext);
  const navigate = useNavigate();


  const onAccountSelect = (account: AccountAccountProfileView) => {
    userContext.setAccountKey(account?.resource?.key);
    userContext.setAccount(account);
    setAccount(account);
    setAccountkey(account?.resource?.key as string)
    navigate('/');
    userContext.setRequireRefresh();
  };


  useEffect(() => {
    if(pageKey) {
      refetch();
    }
  }, [pageKey, refetch]);

  useEffect(()=> {
    document.title = 'Account Picker | Roanuz Falcon';
  },[])


  return (
    <AccountPickerStyle>
      <Font23Bold>Select Account</Font23Bold>
      <GqlStatus loading={loading} error={error} />
      {data && (
        <>
          <ul>
            {data.account_list.accounts.map((account) => (
              <li key={account?.resource?.key}>
                <button className="link-btn" onClick={() => {
                  onAccountSelect(account);
                  userContext.setSport(null);
                }}>
                  <div>
                    {account?.profile?.picture_url ? <AccountImageView account={account} size={28} />
                    : <img src={DefaultImage} alt="account"/>}
                    <Font16Medium>{account.profile.name}</Font16Medium>
                  </div>
                  <ArrowIcon />
                </button>
              </li>
            ))}
          </ul>
          <div className="button-wrapper">
            <div className="pagination">
              {data?.account_list?.page?.prev_page_key &&
                <button onClick={() => setPageKey(data?.account_list?.page?.prev_page_key)}>
                  <PrevIcon />
                </button>
              }
              {data?.account_list?.page?.next_page_key &&
                <button onClick={() => setPageKey(data?.account_list?.page?.next_page_key)}>
                  <NextIcon />
                </button>
              }
            </div>
          </div>
        </>
      )}

    </AccountPickerStyle>
  )
}