import { useContext, useEffect } from "react";
import { Font16Medium, Font23Bold } from "typography/typography";
import { styled } from "ui/stitches.config";
import { ReactComponent as CricketIcon } from '../../ui/cricket/match/assets/icon-cricket.svg';
import { ReactComponent as ArrowIcon } from '../../ui/cricket/match/assets/icon-arrow-right-filled.svg';
import { UserContext } from "web/UserContext";
import { useNavigate } from "react-router-dom";
import { getAccountKey } from "web/localStore";

const SportsSelectWrapper = styled('div' , {  
  padding: '30px 0',
  maxWidth: '320px',
  margin: '0 auto',

  'h2': {
    padding: '0 0 30px',
    textAlign: 'center',
  },

  '.links-wrapper': {
    '>button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px 20px',
      transition: 'background 0.3s linear',
      color: '$text1',
      outline: 'none',
      cursor: 'pointer',
      width: '100%',
      background: '$colorSecondary',
      borderRadius: '8px',
      marginBottom: '8px',
      border: '1px solid $colorSecondary',

      '&:hover': {
        background: 'transparent',
      },
      '.content-wrapper': {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      },
      '.icon-wrapper': {
        background: '#F8964C',
        padding: '8px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    }
  }
})

export function SportsSelector() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const onSelect = (sport: string | null) => {
    userContext.setSport(sport);
    userContext.setRequireRefresh();
    navigate(`/account/${userContext?.account?.resource?.key}/cricket`);
  };

  useEffect(()=>{
    document.title = 'Sports Picker | Roanuz Falcon'
  },[])

  return (
    <SportsSelectWrapper>
      <Font23Bold>Choose a sport</Font23Bold>
      <div className="links-wrapper">
        <button onClick={() => onSelect('Cricket')}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <CricketIcon />
            </div>
            <Font16Medium>Cricket</Font16Medium>
          </div>
          <ArrowIcon />
        </button>
      </div>
    </SportsSelectWrapper>
  );
}

export default SportsSelector;
