import { ApolloError, StoreObject } from "@apollo/client";

export type StoreResource = {
  _hashkey: string;
} & StoreObject;

export type StoreView = {
  resource: StoreResource;
};

export interface ResponseStatusItem {
  msg: string;
  code?: string | null;
  field_path?: string | null;
}

export interface ResponseStatus {
  okay: boolean | null;
  errors: ResponseStatusItem[] | null;
}

export interface WithResponseStatus {
  status: ResponseStatus;
}

export interface RzError {
  message: string;
  errorClass: string | null;
  code: string | null;
  status: number | null;
  fieldPath?: string | null;
}

export class GQLError {
  apollo: ApolloError | null;
  status: ResponseStatus | null;
  constructor(apollo: ApolloError | null = null, status: ResponseStatus | null = null) {
    this.apollo = apollo;
    this.status = status;
  }
  getRzErrors(): RzError[] {
    const errors = [];
    if (this.apollo && this.apollo.graphQLErrors.length > 0) {
      for (let i = 0; i < this.apollo.graphQLErrors.length; i += 1) {
        const gError = this.apollo.graphQLErrors[i];
        if (gError.extensions.rzerror) {
          const rze = gError.extensions.rzerror as any;
          errors.push({
            message: gError.message,
            errorClass: rze.class,
            code: rze.code,
            status: rze.status,
            fieldPath: rze.field_path || null
          });
        }
      }
    }
    if (this.status && this.status.errors) {
      for (let i = 0; i < this.status.errors.length; i += 1) {
        const sError = this.status.errors[i];
        errors.push({
          message: sError.msg,
          errorClass: null,
          code: sError.code || null,
          status: null,
          fieldPath: sError.field_path || null
        });
      }
    }
    return errors;
  }
  getRzError(): RzError | null {
    const errors = this.getRzErrors();
    if (errors.length > 0) {
      return errors[0];
    }
    return null;
  }
  hasError(): boolean {
    return this.getRzError() !== null;
  }
}

export function isResponseStatus(obj: any): obj is ResponseStatus {
  return obj && (obj as ResponseStatus).okay !== undefined;
}

export function isWithResponseStatus(obj: any): obj is WithResponseStatus {
  return obj && (obj as WithResponseStatus).status !== undefined;
}

export function getResponseStatus(obj: any): ResponseStatus | null {
  if (isWithResponseStatus(obj) && isResponseStatus(obj.status)) {
    return obj.status;
  }

  if (isResponseStatus(obj)) {
    return obj;
  }

  return null;
}
