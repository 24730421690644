import { AccountAccountProfileView } from "core/gql/graphql";
import { ApiApp } from "../core/types";

export function getLocalItem<Type>(key: string, defaultValue: Type | undefined = undefined): Type | undefined {
  const val = window.localStorage.getItem(key);
  if (val === null) {
    return defaultValue;
  }

  return val as Type;
}

export function setLocalItem(key: string, value: any){
  window.localStorage.setItem(key, value);
}

export function unsetLocalItem(key: string){
  window.localStorage.removeItem(key);
}

// export function getToken() {
//   return getLocalItem('rzv5apitoken', '') || null;
// }

// export function setToken(token: string) {
//   setLocalItem('rzv5apitoken', token);
// }

export function newApiApp(): ApiApp {
  return {
    token: null,
    tokenExpiry: null,
    projKey: null,
    apiKey: null,
    accountKey: null,
  };
}

export function getToken() {
  return localStorage.getItem('rztoken');
}

export function saveToken(token: string) {
  return localStorage.setItem('rztoken', token);
}

export function removeToken() {
  return localStorage.removeItem('rztoken');
}

export function getAccountKey() {
  return localStorage.getItem('rzaccountkey');
}

export function getSport() {
  return localStorage.getItem('rzsport');
}

export function getAccount() {
  return localStorage.getItem('account');
}

export function getRecacheRequired() {
  return localStorage.getItem('recache');
}

export function setRecacheRequired(recache: boolean) {
  return localStorage.setItem('recache',recache.toString());
}

export function saveSport(sport: string | null) {
  if (!sport) {
    return localStorage.removeItem('rzsport');
  }
  return localStorage.setItem('rzsport', sport);
}

export function setAccount(account: AccountAccountProfileView) {
  if (!account) {
    return localStorage.removeItem('account');
  }
  return localStorage.setItem('account', JSON.stringify(account));
}

export function setAccountkey(accountkey: string) {
  localStorage.setItem('rzaccountkey', accountkey);
}

export function unsetToken() {
  const apiAppRaw = getApiApp();
  apiAppRaw.token = null;
  apiAppRaw.tokenExpiry = null;
  setApiApp(apiAppRaw);
}


export function unsetApiApp() {
  const apiAppRaw = getApiApp();
  apiAppRaw.token = null;
  apiAppRaw.tokenExpiry = null;
  unsetLocalItem('rzv5apiapp');
}


export function getApiApp(): ApiApp {
  const apiAppRaw = getLocalItem('rzv5apiapp', null);
  const apiApp = apiAppRaw ? JSON.parse(apiAppRaw) : newApiApp();
  return apiApp;
}

export function setApiApp(apiApp: ApiApp) {
  setLocalItem('rzv5apiapp', JSON.stringify(apiApp));
}
