import { RouteObject } from "react-router-dom";

export const cricketRoutes: RouteObject[] = [
  {
    index: true,
    async lazy() {
      const { Page } = await import('./home');
      return { Component: Page }
    },
  },
  {
    path: "batting",
    async lazy() {
      const { Page } = await import('./home');
      return { Component: Page }
    },
  },
  {
    path: "tournament/:tournamentkey",
    async lazy() {
      const { TournamentController } = await import('./tournaments/TournamentController');
      return { Component: TournamentController }
    },
  },
  {
    path: "tournament/:key/team/:teamKey",
    async lazy() {
      const { TournamentTeamsController } = await import('./tournaments/TournamentTeamsController');
      return { Component: TournamentTeamsController }
    },
  },
  {
    path: "match/:matchKey",
    async lazy() {
      const { MatchSummary } = await import('./MatchSummary');
      return { Component: MatchSummary }
    },
  },
  {
    path: "match/:matchKey/model/:modelKey",
    async lazy() {
      const { MatchSummary } = await import('./MatchSummary');
      return { Component: MatchSummary }
    },
  },
  {
    path: "country/:countryKey",
    async lazy() {
      const { AssociationListByCountryController }  = await import ('./AssociationListByCountryController');
      return { Component : AssociationListByCountryController }
    }
  },
  {
    path: "association/list", 
    async lazy() {
      const { AssociationListController }  = await import ('./AssociationsListController');
      return { Component : AssociationListController }
    }
  },
  {
    path: 'country/list',
    async lazy() {
      const { CountryListController } = await import('./CountryListController');
      return { Component: CountryListController }
    }
  },
  {
    path: "association/:associationKey",
    async lazy() {
      const { AssociationFeaturedTournamentsController } = await import ('./AssociationFeaturedTournamentsController');
      return { Component: AssociationFeaturedTournamentsController}
    }
  },
  {
    path: "./venue-stats/:key",
    async lazy() {
      const { VenueStatsController } = await import('./VenueStatsController');
      return { Component: VenueStatsController }
    },
  },
  {
    path: "team/:teamKey",
    async lazy() {
      const { TeamController } = await import('./TeamController');
      return { Component: TeamController }
    },
  },
  {
    path: "player-stats/:playerKey",
    async lazy() {
      const { PlayerStatsController } = await import('./PlayerStatsController');
      return { Component: PlayerStatsController }
    },
  },
  {
    path: "scheduler/:scheduledkey",
    async lazy() {
      const { SchedularController } = await import('./Schedular/SchedularController');
      return { Component: SchedularController }
    }
  },
  {
    path: "news",
    async lazy() {
      const { CrikcetNewsController } =await import('./News/CricketNewsController');
      return { Component: CrikcetNewsController }
    }
  }
];