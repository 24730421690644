import { RouteObject } from "react-router-dom";
import { cricketRoutes } from "./pages/cricket/routes";
import ErrorPage from "./pages/error-page";
import { getAccountKey } from "./localStore";
import { PrivateAccountContent } from "./PrivateContent";

export const routes: RouteObject[] = [
  {
    path: "/",
    async lazy() {
      const { Page } = await import('./pages/base');
      return { Component: Page }
    },
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        async lazy() {
          const { Page } = await import('./pages/home');
          return { Component: () => <PrivateAccountContent><Page /></PrivateAccountContent>}
        },
      },
      {
        path: "login",
        async lazy() {
          const { Page } = await import('./pages/login');
          return { Component: Page }
        },
      },
      {
        path: "pages/OnLoginCallback",
        async lazy() {
          const { OnLoginCallback } = await import('./pages/OnLoginCallback');
          return { Component: OnLoginCallback }
        },
      },
      {
        path: "account/picker",
        async lazy() {
          const { AccountPicker } = await import('./AccountPicker');
          return { Component: AccountPicker }
        },
      },
      {
        async lazy() {
          const { Page } = await import('./pages/cricket/base');
          return { Component:  () => <PrivateAccountContent><Page /></PrivateAccountContent> }
        },
        path: `account/:id/cricket/`,
        children: cricketRoutes,
        id: getAccountKey() as string,
      }
    ],

  }
];
