// import type * as Stitches from '@stitches/react';
import { createStitches } from '@stitches/react';

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      gray500: 'hsl(206,10%,76%)',
      blue500: 'hsl(206,100%,50%)',
      purple500: 'hsl(252,78%,60%)',
      green500: 'hsl(148,60%,60%)',
      red500: 'hsl(352,100%,62%)',

      bg: '#0D1116',
      bg2: '#44475a',
      linkText: '#f1fa8c',
      
      code1: '#bd93f9',
      code2: '#50fa7b',
      code3: '#ff79c6',
      code4: '#8be9fd',
      codeBackground: '#44475a',
      codeText1: '#F8F8F2',
      codeText2: '#6272A4',
      code5: '#ffb86c',
      text1: '#fff',
      label: '#C5C5C5',
      border1: '#212932',
      border2: '#303943',
      buttonPrimaryBg: '#F5FF78',
      textRev: '#11161B',
      codeLinkBg: '#26272c',
      colorPrimaryBg: '#F5FF78',
      colorSecondary: '#161c23',
      colorDisabled: '#4F5B68',
      codeSecondaryBg: '#161C23',
    
      // new theme colors

      colorTeal1: "#559688",
      colorTeal2: "#69C2AC",
      colorBlue1: "#3D70A5",
      colorBlue2: "#3D70A5",
      colorBrown: "#A55A3A",
      colorLightBlue: "#8BAFC9",
      colorLightPink: "#D3ABB5",
      colorGreen: "#5F665C",
      colorYellow1: "#E1E68E",
      colorYellow2: "#E8BF5E",
      colorYellow3: "#F5FF78",
      colorDarkBlue1: "#212932",
      colorDarkBlue2: "#161C23",
      colorDarkBlue3: "#0D1116",
      colorWhite: "#FFFFFF",
      colorGrey: "#C5C5C5",
      colorRed: "#E04E4E",
      colorPink1: "#FF81F4",
      colorPink2: "#B24452",
      colorBackground1: '#E4E5DE',
      colorSecondaryBorder: '#303943',
    },
    space: {
      1: '5px',
      2: '10px',
      3: '15px',
    },
    fontSizes: {
      1: '12px',
      2: '13px',
      3: '15px',
    },
    fonts: {
      untitled: 'Untitled Sans, apple-system, sans-serif',
      fontFam: '"neue-kabel",sans-serif',
    },
    fontWeights: {},
    lineHeights: {},
    letterSpacings: {},
    sizes: {
      containerLg: '1080px',
      containerMd: '700px',
      containerSm: '300px',
    },
    borderWidths: {},
    borderStyles: {},
    radii: {},
    shadows: {},
    zIndices: {},
    transitions: {},
  },
  media: {
    bp1: '(min-width: 320px)',
    bp2: '(min-width: 768px)',
    bp3: '(min-width: 992px)',
    bp4: '(min-width: 1194px)',
  },
  utils: {
    // marginX: (value: Stitches.ScaleValue<'space'>) => ({
    //   marginLeft: value,
    //   marginRight: value,
    // }),
  },
});

export const reset = {
  "html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video": {
    margin: "0",
    padding: "0",
    border: "0",
    fontSize: "100%",
    font: "inherit",
    verticalAlign: "baseline",
  },
  "article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section": {
    display: "block",
  },
  "*[hidden]": {
    display: "none",
  },
  body: {
    lineHeight: "1",
  },
  "ol, ul": {
    listStyle: "none",
  },
  "blockquote, q": {
    quotes: "none",
  },
  "blockquote:before, blockquote:after, q:before, q:after": {
    content: "",
    // @ts-ignore
    content: "none",
  },
  table: {
    borderSpacing: "0",
  },
};

export const globalStyles = globalCss(
  {
    ...reset,
    body: {
      color: '$text',
    },
  }
);
