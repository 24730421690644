import { TypedDocumentNode } from '@apollo/client';
import { gql } from './gql';
import { ProfileQueryQuery, ProfileQueryQueryVariables } from './gql/graphql';

export const LoginMutation = gql(`
  mutation LoginMutation($email: String!, $password: String!) {
    core_login(email: $email, password: $password, next_url: "string") {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
      token
      require_change_password
      next_url
    }
  }
`);


// interface ProfileQueryRes {
//   core_profile: UserProfileRes;

// }

export const ProfileQuery: TypedDocumentNode<ProfileQueryQuery, ProfileQueryQueryVariables> = gql(`
  query ProfileQuery {
    core_profile {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
      user {
        resource {
          key
          _hashkey
        }
        profile {
          email
          first_name
          last_name
          picture_url
          phone
          username
          segment {
            key
            _hashkey
          }
        }
        status {
          active
          created_by_apikey
          segment {
            key
            _hashkey
          }
        }
        roles {
          roles_v2
        }
        accounts {
          accounts {
            account {
              key
              _hashkey
            }
            role_v2
          }
          invitations_count
        }
      }
    }
  }
`);

export const AccountsQuery = gql(`
  query AccountsQuery($pageKey: String) {
    account_list(page_key: $pageKey) {
      accounts {
        resource {
          key
          _hashkey
        }
        profile {
          name
          picture_url
        }
        status {
          active
          segment {
            key
            _hashkey
          }
        }
      }
      page {
        next_page_key
        count
        prev_page_key
      }
    }
  }
`);
