import { useContext } from "react";
import { UserContext } from "./UserContext";
import { Login } from "./Login";
import { getApiApp, getSport } from "./localStore";
import { AccountPicker } from "./AccountPicker";
import SportsSelector from "./pages/SportsSelect";


type AccountContentProps = {
  children: JSX.Element;
}

type PrivateContentProps = {
  children: JSX.Element;
}

export function PrivateContent({ children } : PrivateContentProps) {
  const userContext = useContext(UserContext);
  if (!userContext.loggedIn) {
    return (<Login />);
  }

  return children;
}


export function AccountContent({ children }: AccountContentProps): JSX.Element {
  const {apiApp, sport} = useContext(UserContext);
  const chosenApi = getApiApp();
  const chosenSport = getSport();

  if (!apiApp?.accountKey && !chosenApi?.accountKey) {
    return <AccountPicker />;
  }

  if((apiApp?.accountKey || chosenApi?.accountKey) && (!sport && !chosenSport)) {
    return <SportsSelector />
  } 

  return children;
}

export function PrivateAccountContent({ children }: AccountContentProps): JSX.Element {
  return (
    <PrivateContent>
      <AccountContent>
        {children}
      </AccountContent>
    </PrivateContent>
  );
}
