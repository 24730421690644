import { useLazyQuery } from "@apollo/client";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import { ApiApp } from "core/types";
// import { GQLError } from "core/status";
import { GQLError } from "../core/status";
// import { TokenMutation } from "core/user";
import { ProfileQuery } from "../core/user";
import { useApolloClient } from "@apollo/client";
import { getApiApp, unsetApiApp, unsetToken, saveSport, setApiApp } from "./localStore";
import { UserUserViewsProfile, AccountAccountProfileView } from "core/gql/graphql";

interface UserContextInterface {
  apiApp?: ApiApp | null;
  userError?: GQLError;
  userReady: boolean;
  loggedIn: boolean;
  requireRefresh: boolean;
  user: UserUserViewsProfile | null;
  sport: string | null;
  account: any,
  requiredRecache: boolean,
  setLoggedIn: (loggedIn: boolean) => void;
  setUser: (user: UserUserViewsProfile | null) => void;
  setRequireRefresh: () => void;
  setLogout: () => void;
  setAccountKey: (accountKey: string | null) => void;
  setSport: (sport: string | null) => void;
  setAccount: (account: AccountAccountProfileView | null) => void,
  setRequireRecache: (recahce: boolean) => void,
}

export const DefaultUserContext = {
  userReady: false,
  requireRefresh: false,
  user: null,
  loggedIn: false,
  sport: null,
  apiApp: null,
  account: null,
  requiredRecache: false,
  setLoggedIn: (loggedIn: boolean) => null,
  setRequireRefresh: () => null,
  setLogout: () => null,
  setUser: (user: UserUserViewsProfile | null) => null,
  setAccountKey: (accountKey: string | null) => null,
  setSport: (sport: string| null) => null,
  setAccount: (account: AccountAccountProfileView | null) => null,
  setRequireRecache: (recahce: boolean) => false,
};

export const UserContext = createContext<UserContextInterface>(DefaultUserContext);

export const UserContextConsumer = UserContext.Consumer;

function createLogoutState(state: UserContextInterface) {
  return {
    ...state,
    apiApp: null,
    userReady: true,
    account: null,
    loggedIn: false,
    userError: undefined,
    requireRefresh: false,
    requiredRecache: false,
  };
}

export function UserContextProvider({ children }: { children: ReactNode }) {
  const apolloClient = useApolloClient();
  const [getProfile, { error, data, loading: userLoading }] = useLazyQuery(ProfileQuery)

  const [userContext, setUserContext] = useState<UserContextInterface>({
    ...DefaultUserContext,
    setRequireRefresh() {
      setUserContext((state) => ({
        ...state,
        requireRefresh: true,
      }));
    },
    setLoggedIn: (loggedIn: boolean) => {
      setUserContext((state) => ({
        ...state,
        loggedIn,
      }));
    },
    setUser: (user) => {
      let loggedIn = false;
      if (user) {
        loggedIn = true;
      }
      setUserContext((state) => ({
        ...state,
        user,
        loggedIn,
      }));
    },
    setSport: (sport) => {
      setUserContext((state) => ({
        ...state,
        sport,
      }));
      saveSport(sport);
      apolloClient.resetStore();
    },
    setAccountKey(accountKey: string | null) {
      const app = getApiApp();
      const apiApp = {
        ...app,
        accountKey,
      }
      setUserContext((state) => ({
        ...state,
        apiApp,
      }));
      setApiApp({
        ...apiApp,
      });
    },
    setAccount: (account: AccountAccountProfileView | null) => {
      setUserContext((state) => ({
        ...state,
        account,
      }));
    },
    setLogout() {
      unsetApiApp();
      setUserContext((state) => createLogoutState(state));
      // return removeToken().then(() => {
      //   setUserContext((state) => createLogoutState(state));
      // });
    },
    setRequireRecache: (recahce: boolean) => {
      setUserContext((state) => ({
        ...state,
        requiredRecache: recahce,
      }));
    },
  });

  useEffect(() => {
    if (error !== userContext.userError) {
      const userError = new GQLError();
      userError.apollo = error || null;
      const rzError = userError.getRzError();
      if (rzError && rzError.status === 400) {
        console.log("Invalid token logging out user");
        unsetToken();
        setUserContext((state) => createLogoutState(state));
      } else {
        console.log("Some error", userError.getRzErrors(), error);
        setUserContext((state) => ({
          ...state,
          userError,
        }));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const receivedProfile = data?.core_profile && data.core_profile.user;
    console.log('r', receivedProfile);
    if (receivedProfile && (
      !userContext.user
      || userContext.user.profile.email !== receivedProfile.profile.email
      || !userContext.loggedIn
    )) {
      const userReady = true;
      const loggedIn = true;
      const userError = new GQLError();
      userError.status = data.core_profile.status;
      // const apiApp = {
      //   ...(newApiApp()),
      //   ...userContext.apiApp,
      //   token: data.core_login.token,
      //   // tokenExpiry: data.auth.expires || 0,
      // }

      if (userError.hasError()) { 

        console.log("😲 Error in user profile");
        setUserContext((state) => ({
          ...state,
          userError,
          requireRefresh: false,
        }));
        return;
      }
      
      console.log("🙋‍♀️🙋‍♂️ Updating new profile");
      setUserContext((state) => ({
        ...state,
        // apiApp,
        userReady,
        loggedIn,
        userError,
        requireRefresh: false,
        user: receivedProfile,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (userContext.requireRefresh) {
      setUserContext((state) => ({
        ...state,
        requireRefresh: false,
        userError: undefined,
      }));
      getTokenAndProfile(getApiApp());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.requireRefresh]);

  const getTokenAndProfile = (apiApp?: ApiApp) => {
    if (
      apiApp
      && apiApp.token
      && (!userContext.user || userContext.requireRefresh)
      && !userContext.userError
      && !userLoading
    ) {
      console.log("🥷🥷 Performing profile query");
      getProfile();
    }

    // if (apiApp && apiApp.apiKey && userContext.apiApp?.token !== apiApp.token) {
    //   console.log("About to fetch profile");
    //   if (apiApp.token) {
    //     setUserContext((state) => ({
    //       ...state,
    //       apiApp,
    //       userReady: true,
    //       loggedIn: true,
    //     }));
    //   } else {
    //     console.log("🥷🥷 Performing token mutation");
    //     getToken({ variables: { apiKey: apiApp.apiKey } });
    //   }
    // }
  };

  useEffect(() => {
    getTokenAndProfile(getApiApp());
  });

  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
}
