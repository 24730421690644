import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from as linkFrom,
  NormalizedCacheObject,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

import { WebConfig } from "./webConfig";
import { getApiApp } from "./localStore";
import { getRecacheRequired } from "./localStore";

const logLink = new ApolloLink((operation, forward) => {
  // const timerName = `===>⏳ Request - ${operation.operationName}`;
  // console.time(timerName);
  const startedAt = new Date().getTime();
  return forward(operation).map((result) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    const cacheTag = (headers && headers.get("x-cache")) || "N/A";
    const totalTime = new Date().getTime() - startedAt;
    console.log(`===>⏳ Cache Tag ${operation.operationName}: ${cacheTag} ${totalTime}ms`);
    // console.timeEnd(timerName);
    return result;
  });
});

const authLink = () =>
  setContext(async (request, { headers }) => {
    const apiApp = getApiApp();
    console.log("Token", apiApp);
    return {
      headers: {
        ...headers,
        rzapikey: apiApp.apiKey || WebConfig.apiKey || '',
        rztoken:  apiApp.token || '',
        rzaccountkey: apiApp.accountKey,
        ...(getRecacheRequired() === 'true' && {
          rzcachestate: 'Recache'
        })

      },
    };
  });

function createIsomorphLink() {
  // const apiApp = getApiApp();
  return new HttpLink({
    // uri: WebConfig.gqlSrc.replace("<PROJ_KEY>", apiApp.projKey || ""),
    uri: WebConfig.gqlSrc,
    credentials: "same-origin",
  });
}

function createApolloClient(initialState: NormalizedCacheObject | null) {
  // const link = authLink(userToken).concat(createIsomorphLink());
  const links = [authLink(), createIsomorphLink()];

  if (!WebConfig.isProd) {
    links.unshift(logLink);
  }
  const link = linkFrom(links);

  let cache = new InMemoryCache({});
  if (initialState) {
    console.log("🧲🧲 Restoring Apollo data");
    cache = cache.restore(initialState);
  } else {
    console.log("⚡️⚡️ New Apollo");
  }

  return new ApolloClient({
    ssrMode: false,
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
        // fetchPolicy: 'cache-and-network',
      },
      query: {
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  });
}

export function initApollo(initialState: NormalizedCacheObject | null = null) {
  return createApolloClient(initialState);
}
